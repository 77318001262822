<template>
  <div class="share" @click="clickShare">
  </div>
</template>

<script>
export default {
  name: "Share",
  methods: {
    clickShare: function () {
      // 随机三个文案
      let shareText = [
        {
          title: "我正在参加投你“喜”好活动，一起来投票吧！",
          content: "快来pick你期待的内容吧！",
        },
        {
          title: "奔走相告！平安大学千人千面即将上线喜马拉雅精品内容…",
          content: " 大咖讲书、精品课…精彩你说了算！",
        },
        {
          title: "喜马拉雅即将来袭！快来投你“喜”好！",
          content: "诚邀你参加平安大学投票活动！",
        }
      ];

      let random = parseInt(Math.floor(Math.random() * 3));

      zn.biz.util.znShare({
        type: 0,
        url: "https://pazn.leadmea.com/",
        title: shareText[random].title,
        content: shareText[random].content,
        image: "https://pazn.leadmea.com/html/img/ws_share_img.png",
        onFail: function (e) {
        },
        onSuccess: function (e) {
        }
      });

      MtaH5.clickStat("sharefixed");
    },
  }
}
</script>

<style scoped lang="scss">
.share {
  position: fixed;
  width: 1.24rem;
  height: 0.9rem;
  right: 0;
  top: 50%;
  margin-top: -0.45rem;
  z-index: 2;
  background: {
    image: url("~@/assets/images/share_bg.png");
    size: 100%;
    position: center;
    repeat: no-repeat;
  };
}

</style>
<template>
  <div class="modal" :class="show ? 'active' : ''">
    <ModalBg></ModalBg>
    <!-- 成功 -->
    <div class="modal-content">
      <slot></slot>
      <div class="modal-close" @click="close"></div>
    </div>
  </div>
</template>

<script>
import ModalBg from "@/components/ModalBg";

export default {
  name: "ModalWrapper",
  data: function () {
    return {
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }

  },
  components: {
    ModalBg,
  },
  methods: {
    close: function () {
      console.log("close modal");
      this.$EventBus.$emit("hideModal", false);
    }
  },
  mounted() {
    const _this = this;

    this.$EventBus.$on("showModal", (msg) => {
      console.log("msg", msg);
      _this.close();
    });
  }
}
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  touch-action: none;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: all .2s;

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

/* 关闭按钮 */
.modal-close {
  position: absolute;
  width: .8rem;
  height: .8rem;
  right: -0.02rem;
  top: -1rem;
  background: {
    image: url("~@/assets/images/modal_close.png");
    size: .6rem .6rem;
    position: center right;
    repeat: no-repeat;
  };

}

.modal-content {
  position: relative;
}

</style>
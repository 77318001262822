import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

import {API_URL} from "@/http/config";

const ApiService = {
    init() {
      Vue.use(VueAxios, axios);
      Vue.axios.defaults.baseURL = API_URL;
    },

    get(resource, params) {
      return Vue.axios.get(resource, {
        params: params
      }).catch(error => {
        throw new Error(`${error}`);
      })
    },

    // 如果是目标用户调动登录
    post(resource, params) {
      return axios({
        method: "post",
        url: API_URL + "/" + resource,
        data: params
      })
    }

  }
;

export default ApiService;

export const getType1 = function (data) {
  return ApiService.get("big-shot", data);
}

export const getType2 = function (data) {
  return ApiService.get("hot-class", data);
}

export const getType3 = function (data) {
  return ApiService.get("audio-book", data);
}

export const login = function (payload) {
  return ApiService.post("login", payload);
}

export const votes = function (payload) {
  return ApiService.post("votes", payload);
}

export const share = function (url) {
  return ApiService.get("wechat-share", url)
}












<template>
  <div class="item">
    <div class="wrapper"
         :class="[data.voteStatus == '1' ? 'active' : '', userInfo.is_votes == '2' ? 'over' : '']">
      <template v-if="userInfo.is_votes == '1'">
        <!-- 选中 -->
        <div class="selected" :class="data.voteStatus == '1' ? 'active' : ''">
          <img class="selected-bg selected-bg-yes" src="~@/assets/images/select_yes.png" alt="">
          <img class="selected-bg selected-bg-no" src="~@/assets/images/select_no.png" alt="">
        </div>
      </template>

      <template v-else>
        <!-- 已经投过票 -->
        <div class="selected" :class="data.voteStatus == '1' ? 'active' : ''">
          <img class="selected-bg selected-bg-yes" src="~@/assets/images/select_yes_disable.png" alt="">
          <img class="selected-bg selected-bg-no" src="~@/assets/images/select_no_disable.png" alt="">
        </div>
      </template>


      <div class="pic-bg">
        <img class="avatar" :class="userInfo.is_votes == '2' ? 'over' : ''" :src="data.image" alt="">
      </div>
      <div class="info">
        <slot></slot>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ListItem",
  props: {
    data: {
      type: Object,
      default: null
    },
    userInfo: {
      type: Object,
      default: {}
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  display: flex;
  margin-bottom: .2rem;
  width: 6.94rem;
  align-items: center;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
}

.wrapper {
  position: relative;
  margin: 0 auto;
  width: 6.44rem;
  border: solid 0.02rem rgba(0, 0, 0, 0);
  border-radius: 0.04rem;
  background: #ffe6b0;
  display: flex;
  padding: .2rem 0 .2rem 0;

  &.active {
    border: solid 0.02rem rgb(255, 129, 47);

    &.over {
      border: solid 0.02rem rgb(245, 188, 66);
    }
  }
}

.pic-bg {
  position: relative;
  margin-left: .26rem;
  margin-right: 0.4rem;
  width: 2.04rem;
  height: 2.04rem;

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 0.04rem;
    width: 100%;
    height: 100%;
    right: -0.07rem;
    bottom: -0.07rem;
    border: solid rgb(255, 129, 47) 0.02rem;
    background: {
      color: #f6ce49;
    };
  }

  .avatar {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    border: solid rgb(255, 129, 47) 0.02rem;
    border-radius: 0.04rem;
    background: {
      color: #fff5d0;
    };

    &.over {
      border-color: rgb(245, 188, 66);
    }
  }
}

.selected {
  position: absolute;
  width: .42rem;
  height: .42rem;
  right: .12rem;
  top: .12rem;

  &.active {
    .selected-bg {
      &.selected-bg-no {
        opacity: 0;
      }

      &.selected-bg-yes {
        opacity: 1;
      }
    }
  }

  .selected-bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all .2s;

    &.selected-bg-no {
      opacity: 1;
    }
  }
}

.info {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .name {
    color: #222;
    font-size: .28rem;
    font-weight: bold;
    margin-bottom: .12rem;
  }

  .desc {
    font-size: .2rem;
    color: #333;
    margin-bottom: .22rem;
  }

  .book {
    font-size: .28rem;
    color: #222;
    font-weight: bold;
    margin-bottom: .12rem;
  }

  .works {
    font-size: .26rem;
    color: #222;
    font-weight: bold;
    margin-bottom: .12rem;
  }

  .speaker {
    color: #222;
    font-size: .24rem;
    font-weight: bold;
  }

  .author {
    color: #222;
    font-size: .24rem;
    font-weight: bold;
    margin-bottom: .2rem;
  }

  .no-margin {
    margin-bottom: 0 !important;
  }

  .next-line {
    white-space: pre-line;
  }

  .ml {
    margin-left: -0.14rem;
  }

  .flex {
    display: flex;
  }

}


</style>
<template>
  <div class="main over">
    <ModalBg></ModalBg>
    <div class="content">
      <img class="over-pic" src="~@/assets/images/over.png" alt="">
    </div>
  </div>
</template>

<script>
import ModalBg from "@/components/ModalBg";

export default {
  name: "ModalOver",
  components: {
    ModalBg
  }
}
</script>

<style scoped lang="scss">
.main {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 2;
  touch-action: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top:  1.05rem;
}

.over-pic {
  position: relative;
  margin: 0 auto;
  display: block;
  width: 6.22rem;
  height: 6.36rem;
}
</style>
import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false;

Vue.prototype.$EventBus = new Vue();

import ApiService from "@/http/api.service";

ApiService.init();

// import Swiper styles
import 'swiper/swiper-bundle.css';

const v = new Vue({
  render: h => h(App),
}).$mount('#app');


let timer = null;

function resize() {
  let clientWidth;
  if (document.body.clientWidth) {
    clientWidth = document.body.clientWidth;
  } else {
    console.log("document.documentElement");
    clientWidth = document.documentElement.clientWidth;
  }

  let htmlFontSize = (100 * clientWidth) / 750;
  document.getElementsByTagName("html")[0].style.fontSize = htmlFontSize + "px";
}

resize();
window.addEventListener("resize", function () {
  clearInterval(timer);
  timer = setTimeout(function () {
    resize()
  }, 30);
});


import {share} from "@/http/api.service";

function wxShare(url) {
  console.log("请求分享的 url", url);
  share({
    url: url
  }).then(res => {
    const data = res.data.content;

    let shareText = [
      {
        title: "我正在参加投你“喜”好活动，一起来投票吧！",
        content: "快来pick你期待的内容吧！",
      },
      {
        title: "奔走相告！平安大学千人千面即将上线喜马拉雅精品内容…",
        content: " 大咖讲书、精品课…精彩你说了算！",
      },
      {
        title: "喜马拉雅即将来袭！快来投你“喜”好！",
        content: "诚邀你参加平安大学投票活动！",
      }
    ];

    let random = parseInt(Math.floor(Math.random() * 3));

    wx.config({
      // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: data.appid, // 必填，公众号的唯一标识
      timestamp: data.timestamp, // 必填，生成签名的时间戳
      nonceStr: data.noncestr, // 必填，生成签名的随机串
      signature: data.signature,// 必填，签名
      jsApiList: ["updateAppMessageShareData", 'onMenuShareTimeline', 'onMenuShareAppMessage'] // 必填，需要使用的JS接口列表
    });

    wx.ready(function () {
      wx.updateAppMessageShareData({
        title: shareText[random].title,
        desc: shareText[random].content,
        link: "https://pazn.leadmea.com/",
        imgUrl: "https://pazn.leadmea.com/html/img/ws_share_img.png",
      });

      wx.onMenuShareTimeline({
        title: shareText[random].title,
        desc: shareText[random].content,
        link: "https://pazn.leadmea.com/",
        imgUrl: "https://pazn.leadmea.com/html/img/ws_share_img.png",
      });

      wx.onMenuShareAppMessage({
        title: shareText[random].title,
        desc: shareText[random].content,
        link: "https://pazn.leadmea.com/",
        imgUrl: "https://pazn.leadmea.com/html/img/ws_share_img.png",
      });
    })
  })
}

let href = window.location.href;

if (window.navigator.userAgent.toLocaleLowerCase().indexOf("weixin")) {
  // 微信平台进行微信分享
  wxShare(href);
}











<template>
  <ModalWrapper :show="show">
    <div class="main success">
      <img class="success-icon" src="~@/assets/images/modal_success_icon.png" alt="">
      <div class="success-title">投票成功</div>
      <div class="success-text">您已投票成功，想让资源出道，分享给更多<br>好友投票吧！</div>

      <div class="success-submit" @click="share">
        <img class="success-submit-bg" src="~@/assets/images/modal_success_btn.png" alt="">
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import ModalWrapper from "@/components/ModalWrapper";

export default {
  name: "ModalSuccess",
  data: function () {
    return {}
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModalWrapper
  },
  methods: {
    share: function () {
      // 随机三个文案
      let shareText = [
        {
          title: "我正在参加投你“喜”好活动，一起来投票吧！",
          content: "快来pick你期待的内容吧！",
        },
        {
          title: "奔走相告！平安大学千人千面即将上线喜马拉雅精品内容…",
          content: " 大咖讲书、精品课…精彩你说了算！",
        },
        {
          title: "喜马拉雅即将来袭！快来投你“喜”好！",
          content: "诚邀你参加平安大学投票活动！",
        }
      ];

      let random = parseInt(Math.floor(Math.random() * 3));

      zn.biz.util.znShare({
        type: 0,
        url: "https://pazn.leadmea.com/",
        title: shareText[random].title,
        content: shareText[random].content,
        image: "https://pazn.leadmea.com/html/img/ws_share_img.png",
        onFail: function (e) {
        },
        onSuccess: function (e) {
        }
      });
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.main {
  position: relative;
  margin: 0 auto;
  width: 6rem;
  background: #fff;
  padding: .2rem;
  border-radius: .1rem;
  display: block;
}

.success {
  position: relative;
  width: 6.22rem;
  padding-top: 1.1rem;
  padding-bottom: .9rem;
  background: {
    color: #fff;
  };
  border-radius: .2rem;
}

.success-icon {
  margin: 0 auto;
  margin-bottom: .5rem;
  display: block;
  width: 4rem;
  height: 2.6rem;
}

.success-title {
  margin-bottom: .1rem;
  text-align: center;
  color: #222;
  font-size: .4rem;
  font-weight: bold;
}

.success-text {
  text-align: center;
  color: #222;
  font-size: .3rem;
  line-height: .44rem;
  margin-bottom: 0.6rem;
}


.success-submit {
  position: relative;
  margin: 0 auto;
  width: 4.9rem;
  height: .85rem;

  .success-submit-bg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

</style>
<template>
  <div id="app">
    <Header></Header>
    <ContentList
        :listType1="listType1"
        :listType2="listType2"
        :listType3="listType3"
        :userInfo="userInfo"
        :isTargetUser="isTargetUser"
        @changeList="changeList"
        @setUserInfo="setUserInfo"
    ></ContentList>

    <!-- 分享按钮 -->
    <Share></Share>

    <!-- 弹窗 -->
    <ModalShare :show="showModal === 'share'"></ModalShare>
    <ModalSuccess :show="showModal === 'success'"></ModalSuccess>

    <!-- userPlatform  != 'zhiniao'   -->
    <ModalBan v-show="userPlatform  != 'zhiniao'"></ModalBan>

    <!-- toast -->
    <div class="toast" :class="toastShow ? 'active' : ''">
      <div class="toast-content">{{ toastMsg }}</div>
    </div>

    <!-- 活动结束 -->
    <ModalOver v-if="true"></ModalOver>

  </div>
</template>

<script>
import Header from "@/components/Header";
import ContentList from "@/components/ContentList";
import Share from "@/components/Share";
import ModalWrapper from "@/components/ModalWrapper";
// 分享弹窗
import ModalShare from "@/components/ModalShare";
// 提交成功弹窗
import ModalSuccess from "@/components/ModalSuccess";
import ModalBan from "@/components/ModalBan";
import ModalOver from "@/components/ModalOver";

import {getType1, getType2, getType3, login, share} from "@/http/api.service";

export default {
  name: 'App',
  data: function () {
    return {
      showModal: "none",
      toastMsg: "",
      toastTimer: null,
      toastShow: false,

      // 用户平台，zhiniao weixin 或者 other
      // 默认 other
      userPlatform: "others",

      // 默认是 false
      umid: false,
      // 是否登录
      isLogin: false,
      // 是否目标用户
      isTargetUser: false,

      // 登录用户信息
      userInfo: {
        user_id: "",
        uid: "",
        is_votes: "1"
      },

      listType1: [],
      listType2: [],
      listType3: [],
    }
  },
  components: {
    Header,
    ContentList,
    Share,
    ModalShare,
    ModalSuccess,
    ModalWrapper,
    ModalBan,
    ModalOver,
  },
  mounted() {
    const _this = this;
    this.$EventBus.$on("showModal", (msg) => {

      _this.showModal = msg;
    });
    this.$EventBus.$on("hideModal", () => {
      _this.showModal = "none";
    });

    this.$EventBus.$on("showToast", (msg) => {
      _this.showToast(msg);
    });

    // 判断用户浏览器
    let navigator = window.navigator.userAgent.toLocaleLowerCase();

    // 是否知鸟平台
    if (navigator.indexOf("zhiniao") >= 0) {
      _this.userPlatform = "zhiniao";
    } else if (navigator.indexOf("wechat") >= 0) {
      _this.userPlatform = "weixin";
    } else {
      _this.userPlatform = "others";
    }

    try {
      // 获取用户信息
      window.zn.ready(() => {
        zn.device.znGetUser({
          appId: "9FB516BD801A4E35A5A9F4427F05C0AA",
          appKey: "B43FDC01A470AD45E054022128574717",
          onSuccess: function (data) {
            _this.umid = data.umid;
            let path = data.allFullPath;

            const pathHead = "0EA3756D964D21C3E054A0369F1934EC";

            if (path.indexOf(pathHead) == 0) {
              _this.isTargetUser = true;
            }

            // 如果是目标用户，需要登录后台
            if (_this.isTargetUser && _this.umid) {
              const params = new FormData();
              params.append("uid", _this.umid);

              login(params).then(res => {
                if (res.data.code == 0) {
                  _this.userInfo = res.data.content;
                  _this.getList({
                    user_id: _this.userInfo.user_id
                  });
                  MtaH5.clickStat("login")
                } else {
                  _this.getList();
                }
              });
            } else {
              _this.getList();
            }
          },
          onFail: function () {
            _this.getList();
          }
        });
      });

    } catch (e) {
      this.getList();
    }
  },
  methods: {
    // 显示 toast
    showToast(msg) {
      this.toastMsg = msg;
      if (!this.toastShow) {
        this.toastShow = true;
        this.toastTimer = setTimeout(() => {
          this.toastShow = false;
        }, 2000);
      }
    },

    // 请求所有列表数据
    getList(data) {
      const _this = this;
      getType1(data).then(data => {
        let list = data.data.content.list;
        _this.listType1 = list;
      });

      getType2(data).then(data => {
        let list = data.data.content.list;
        _this.listType2 = list;
      });

      getType3(data).then(data => {
        let list = data.data.content.list;
        _this.listType3 = list;
      });
    },
    // 修改列表数据
    changeList(index, id) {
      let count = 0;

      let list = [];
      if (index == 0) {
        list = this.listType1;
      } else if (index == 1) {
        list = this.listType2;
      } else if (index == 2) {
        list = this.listType3;
      }

      for (let i = 0; i < list.length; i++) {
        if (list[i].voteStatus == 1) {
          count++;
        }
      }

      if (list[id - 1].voteStatus == 1) {
        list[id - 1].voteStatus = 2;
      } else {
        if (count >= 5) {
          this.$EventBus.$emit("showToast", "每个模块最多只可\n选择5个资源哦");
          return;
        }
        list[id - 1].voteStatus = 1;
      }


    },
    setUserInfo() {
      this.userInfo.is_votes = "2";
    },
  }
}
</script>

<style lang="scss">
html {
  /*防止IOS屏幕横竖屏切换时文字大小产生变化*/
  -webkit-text-size-adjust: 100%;
  /*禁用点击的高亮反馈*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: {
    color: #ff812f;
  };
}

body {
  position: relative;
  font-size: 16px;
  background: {
    color: #ff812f;
  };
}

/* 禁止长按链接与图片弹出菜单 */
a, img {
  -webkit-touch-callout: none;
}

a, a:hover {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

@keyframes toast-ani {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: visible;
  }
}

.toast {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;

  &.active {
    animation: toast-ani linear 2s;
  }

  .toast-content {
    position: relative;
    background: rgba(8, 8, 8, 0.85);
    width: 5rem;
    color: #fff;
    font-size: .26rem;
    line-height: .36rem;
    text-align: center;
    padding: .2rem .6rem;
    border-radius: .2rem;
    white-space: pre-line;
  }
}

#app {
  padding-bottom: 1.4rem;
}


</style>

<template>
    <ModalWrapper :show="show">
      <div class="main share">
        <div class="poster-wrapper">
          <img class="poster-img poster-def" src="~@/assets/images/poster.png" alt="">
        </div>
      </div>
    </ModalWrapper>
</template>

<script>
import ModalWrapper from "@/components/ModalWrapper";

export default {
  name: "ModalShare",
  data: function () {
    return {
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }

  },
  components: {
    ModalWrapper
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.main {
  position: relative;
  margin: 0 auto;
  //width: 6rem;
  background: #fff;
}

.poster-wrapper {
  width: 6.23rem;
  height: 8.57rem;
}

.poster-img {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  -webkit-touch-callout: default;
}

</style>
<template>
  <div class="content">
    <div class="title">
      <img class="title_bg" src="~@/assets/images/vote_title.png" alt="">
      <img class="title-sz title-sz1" src="~@/assets/images/vote_title_zs1.png" alt="">
      <img class="title-sz title-sz2" src="~@/assets/images/vote_title_zs2.png" alt="">
    </div>

    <!-- tab -->
    <div class="tab">
      <div class="tab-wrapper">
        <template v-for="(item, index) in tabList">
          <div
              class="tab-item"
              :class="item.id == currentTab ? 'active' : ''"
              @click="swiperTo(item.id)"
          >
            <div class="tab-text">{{ item.text }}</div>
          </div>
        </template>
      </div>
    </div>

    <!-- list -->
    <div class="list">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <ListItem
                v-for="item in listType1"
                :key="item.id"
                @click.native="checkItem(0, item.id)"
                :data="item"
                :userInfo="userInfo"
            >
              <div class="name">大咖：{{ item.name }}</div>
              <div class="desc next-line">{{ item.desc }}</div>
              <div class="works ml no-margin">{{ item.works }}</div>
            </ListItem>
          </div>
          <div class="swiper-slide">
            <ListItem
                v-for="item in listType2"
                :key="item.id"
                @click.native="checkItem(1, item.id)"
                :data="item"
                :userInfo="userInfo"
            >
              <div class="book next-line">{{ item.name }}</div>
              <div class="desc next-line">{{ item.desc }}</div>
              <div class="speaker">主讲人：{{ item.speaker }}</div>
            </ListItem>
          </div>
          <div class="swiper-slide">
            <ListItem
                v-for="item in listType3"
                :key="item.id"
                @click.native="checkItem(2, item.id)"
                :data="item"
                :userInfo="userInfo"
            >
              <div class="book ml">{{ item.name }}</div>
              <div class="author next-line flex">
                <div>作者：</div>
                <div>{{ item.author }}</div>
              </div>
              <div class="desc no-margin next-line">{{ item.desc }}</div>
            </ListItem>
          </div>
        </div>
      </div>
    </div>

    <!-- rule -->
    <div class="rule">
      <img class="rule-zs" src="~@/assets/images/rule_zs.png" alt="">

      <div class="rule-wrapper">
        <div class="rule-inner">
          <p>1.投票时间：11月30日-12月7日</p>
          <p>2.活动对象：平安集团正式员工，每位员工可投票一次</p>
          <p>3.投票需对三个页面"人气大咖秀"、"热门课程辑"、"超赞有声书"分别投票，每个页面选择至少1门，至多5门内容;</p>
          <p>4.三个页面均投票后,投票成功</p>
        </div>
      </div>
      <img class="rule-title" src="~@/assets/images/rule_title.png" alt="">
    </div>

    <!-- btn -->
    <div class="btn-wrapper">
      <div class="btn" :class="(submitStatus === true && userInfo.is_votes == '1') ? 'active' : ''" @click="sub">
        <img class="btn-bg btn-bg-def" src="~@/assets/images/submit_bg_def.png" alt="">
        <img class="btn-bg btn-bg-act" src="~@/assets/images/submit_bg.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';

import ListItem from "@/components/ListItem";

import {votes} from "@/http/api.service";

export default {
  name: "ContentList",
  data: function () {
    return {
      currentTab: 1,
      tabList: [
        {
          id: 1,
          text: "人气大咖秀",
        },
        {
          id: 2,
          text: "热门课程辑",
        },
        {
          id: 3,
          text: "超赞有声书",
        }
      ],
      swiper: null
    }
  },
  props: {
    listType1: {
      type: Array,
      default: []
    },
    listType2: {
      type: Array,
      default: []
    },
    listType3: {
      type: Array,
      default: []
    },
    userInfo: {
      type: Object,
      default: {}
    },
    isTargetUser: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listType1Select: function () {
      let str = "";
      let arr = [];
      for (let i = 0; i < this.listType1.length; i++) {
        if (this.listType1[i].voteStatus == "1") {
          arr.push(i + 1);
        }
      }

      str = arr.join(",");
      return str;
    },
    listType2Select: function () {
      let str = "";
      let arr = [];
      for (let i = 0; i < this.listType2.length; i++) {
        if (this.listType2[i].voteStatus == "1") {
          arr.push(i + 1);
        }
      }

      str = arr.join(",");
      return str;
    },
    listType3Select: function () {
      let str = "";
      let arr = [];
      for (let i = 0; i < this.listType3.length; i++) {
        if (this.listType3[i].voteStatus == "1") {
          arr.push(i + 1);
        }
      }

      str = arr.join(",");
      return str;
    },
    submitStatus: function () {
      let statusArr = [0, 0, 0];

      const t1 = this.listType1Select == "" ? [] : this.listType1Select.split(",");
      const t2 = this.listType2Select == "" ? [] : this.listType2Select.split(",");
      const t3 = this.listType3Select == "" ? [] : this.listType3Select.split(",");

      statusArr[0] = t1.length;
      statusArr[1] = t2.length;
      statusArr[2] = t3.length;

      let statusBoolean = false;
      if (statusArr[0] >= 1 && statusArr[1] >= 1 && statusArr[2] >= 1) {
        statusBoolean = true;
        return statusBoolean;
      } else {
        return statusArr;
      }
    }
  },
  components: {
    ListItem
  },
  methods: {
    swiperTo: function (e) {
      this.currentTab = e;
      this.swiper.slideTo(e - 1, 500, false);

      MtaH5.clickStat('clickeTab',{'index': e})
    },
    checkItem: function (index, id) {
      if (this.isTargetUser == false) {
        this.$EventBus.$emit("showToast", "仅限集团内部员工参与投票");
        return;
      }

      if (this.userInfo.is_votes == '2') {
        return;
      }

      this.$emit("changeList", index, id);

      try {
        if (index == 0) {
          MtaH5.clickStat('clickList1', {'id': id})
        } else if (index == 1) {
          MtaH5.clickStat('clickList2', {'id': id})
        } else if (index == 2) {
          MtaH5.clickStat('clickList3', {'id': id})
        }
      } catch (e) {
        console.log("统计错误", e);
      }

    },
    sub: function (e) {
      if (this.isTargetUser == false) {
        this.$EventBus.$emit("showToast", "仅限集团内部员工参与投票");
        return;
      }

      // 如果已经投过票
      if (this.userInfo.is_votes == '2') {
        console.log("已经投过票");
        return;
      }

      const _this = this;
      if (this.submitStatus === true) {
        const formData = new FormData();
        formData.append("voteBs", _this.listType1Select);
        formData.append("voteHc", _this.listType2Select);
        formData.append("voteAb", _this.listType3Select);

        if (_this.userInfo.user_id) {
          formData.append("user_id", _this.userInfo.user_id);
        } else {
          if (this.isTargetUser == false) {
            this.$EventBus.$emit("showToast", "仅限集团内部员工参与投票");
            return;
          }
        }

        votes(formData).then(res => {
          let data = res.data;
          console.log(data)
          if (data.code == 0) {
            // 显示投票成功
            this.$EventBus.$emit("showModal", "success");
            this.$emit("setUserInfo");

            MtaH5.clickStat("submit");
          } else if (data.code == 11001) {
            this.$EventBus.$emit("showToast", data.message);
          }

        });
      } else {
        console.log("需要投票", this.submitStatus);

        let typeStringArr = [];
        if (this.submitStatus[0] == 0) {
          typeStringArr.push("人气大咖秀");
        }

        if (this.submitStatus[1] == 0) {
          typeStringArr.push("热门课程辑");
        }

        if (this.submitStatus[2] == 0) {
          typeStringArr.push("超赞有声书");
        }

        let typeString = typeStringArr.join("\"、\"");

        typeString = "\"" + typeString + "\"" + " 您还未选择喜欢的内容 选择好后才可投票哦"

        console.log(typeString);
        this.$EventBus.$emit("showToast", typeString)
      }
    }
  },
  mounted: function () {
    this.swiper = new Swiper(".swiper-container", {
      allowTouchMove: false
    });

    window.addEventListener("scroll", function () {
      let tabWrapper = document.getElementsByClassName("tab-wrapper")[0];
      let btn = document.getElementsByClassName("btn-wrapper")[0];

      let bannerHeight = document.getElementsByClassName("header")[0].offsetHeight;
      let tabTitle = document.getElementsByClassName("title")[0].offsetHeight;

      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop >= (bannerHeight + tabTitle)) {
        tabWrapper.classList.add("fixed");
        btn.classList.add("fixed");
      } else {
        tabWrapper.classList.remove("fixed");
        btn.classList.remove("fixed");
      }

      if (scrollTop >= ((bannerHeight + tabTitle) / 2)) {
        btn.classList.add("fixed");
      } else {
        btn.classList.remove("fixed");
      }

    });
  }
}
</script>

<style scoped lang="scss">
.title {
  position: relative;
  padding-bottom: .1rem;

  .title_bg {
    display: block;
    margin: 0 auto;
    width: 5.04rem;
    height: 1.2rem;
  }

  .title-sz {
    position: absolute;
    display: block;
    pointer-events: none;
  }

  .title-sz1 {
    width: 1.19rem;
    height: 1.04rem;
    left: 0.18rem;
    top: -0.08rem;
  }

  .title-sz2 {
    width: 0.7rem;
    height: 0.67rem;
    right: 0.6rem;
    top: .3rem;
  }
}

.tab {
  position: relative;
  height: 0.92rem;
  padding: 0 0.28rem;

  .tab-wrapper {
    position: relative;
    height: 0.92rem;
    line-height: 0.92rem;
    align-items: flex-end;
    background: #ff812f;

    &.fixed {
      position: fixed;
      width: 6.94rem;
      left: 0.28rem;
      top: 0;
      z-index: 2;
    }
  }

  .tab-item {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex: 1;
    text-align: center;
    background: #eee;
    border: 0.02rem solid #222;
    width: 2.33rem;
    height: .72rem;
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
    background: {
      color: #f5bc42;
    };
    transition: all .2s;

    &:nth-child(1) {
      left: 0;
    }

    &:nth-child(2) {
      left: 2.31rem;
    }

    &:nth-child(3) {
      right: 0;
    }

    &.active {
      height: .92rem;
      background: {
        color: #fff5d0;
      };
    }
  }

  .tab-text {
    margin-top: auto;
    height: .68rem;
    line-height: .68rem;
    font-size: .32rem;
    color: #222;
    font-weight: bold;
  }
}

.list {
  position: relative;
  margin: 0 0.28rem;
  margin-bottom: 0.7rem;
  border: 0.02rem solid #222;
  border-top: none;
  border-bottom-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
  background: {
    color: #fff5d0;
  };
}

.swiper-wrapper {
  padding: 0.23rem 0 0.28rem 0;
}

.rule {
  position: relative;
  padding-bottom: 0.5rem;

  .rule-title {
    position: absolute;
    display: block;
    width: 4.1rem;
    height: 1rem;
    top: -0.56rem;
    left: 50%;
    margin-left: -2.05rem;
    pointer-events: none;
  }

  .rule-wrapper {
    position: relative;
    margin: 0 auto;
    width: 6.9rem;
    border-width: 0.02rem;
    border-color: #2a1c13;
    border-style: solid;
    padding: 0.1rem;
    border-radius: 0.2rem;
    background: {
      color: #f6ce49;
    };

  }

  .rule-inner {
    border-width: 0.02rem;
    border-color: #2a1c13;
    border-style: solid;
    border-radius: 0.15rem;
    padding: 0.56rem 0.16rem 0.3rem 0.16rem;
    background: {
      color: #fff5d0;
    };
    font-size: .26rem;
    line-height: .36rem;
    color: #222;

    p {
      margin-bottom: .12rem;
    }

  }

  .rule-zs {
    display: block;
    position: absolute;
    width: 7.5rem;
    height: 1.72rem;
    bottom: 0.1rem;
    left: 0;
    pointer-events: none;
  }

}


.btn-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1.4rem;
  background: #ffc446;
  overflow: hidden;
  transform: translate(0, 1.4rem);
  transition: all .2s;
  z-index: 2;

  &.fixed {
    transform: translate(0, 0);
  }

  .btn {
    position: relative;
    margin: 0 auto;
    margin-top: .28rem;
    display: block;
    width: 5.5rem;
    height: .85rem;

    &.active {
      .btn-bg-def {
        opacity: 0;
      }

      .btn-bg-act {
        opacity: 1;
      }
    }
  }

  .btn-bg {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all .2s;
  }

  .btn-bg-def {
    opacity: 1;
  }


}


</style>
<template>
  <div class="modal-bg"></div>
</template>

<script>
export default {
  name: "ModalBg"
}
</script>

<style scoped lang="scss">
.modal-bg {
  position: fixed;
  left: -0.05rem;
  top: -0.05rem;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.86);
  box-sizing: content-box;
  padding: .1rem;
}
</style>
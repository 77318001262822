<template>
  <div class="main ban">
    <ModalBg class="mbg"></ModalBg>
    <div class="content">
      <div class="bg"></div>
      <div class="logo"></div>
      <div class="ban-inner">
        <div class="title"></div>
        <div class="subtitle"></div>
        <div class="info"></div>
        <div class="qr">
          <div class="qr-img" alt="">
            <img class="qr-img-pic" src="~@/assets/images/qr.png" alt="">
          </div>
          <a class="qr-link" href="https://www.zhi-niao.com/download.html" target="_blank"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalBg from "@/components/ModalBg";

export default {
  name: "ModalBan",
  data: function () {
    return {}
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModalBg
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.mbg {
  background: {
    color: #ff812f;
  };
}

.main {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 2;
  touch-action: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top:  1.05rem;
}

.content {

}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 7.5rem;
  height: 4.8rem;
  background: {
    image: url("~@/assets/images/ban_bg1.png");
    size: 100%;
    position: center;
    repeat: no-repeat;
  };
}

.logo {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.4rem;
  width: 2.8rem;
  height: .76rem;
  background: {
    image: url("~@/assets/images/ban_icon.png");
    size: 100%;
    position: center;
    repeat: no-repeat;
  };
}

.ban-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  margin:0 auto;
  margin-bottom: .24rem;
  position: relative;
  width: 6.66rem;
  height: .8rem;
  background: {
    image: url("~@/assets/images/ban_title.png");
    size: 100%;
    position: center;
    repeat: no-repeat;
  };
}

.subtitle {
  margin: 0 auto;
  margin-bottom: 0.34rem;
  position: relative;
  width: 5rem;
  height: .76rem;
  background: {
    image: url("~@/assets/images/ban_subtitle.png");
    size: 100%;
    position: center;
    repeat: no-repeat;
  };
}

.info {
  position: relative;
  width: 7.5rem;
  height: 2.46rem;
  margin-bottom: .2rem;
  background: {
    image: url("~@/assets/images/ban_info.png");
    size: 100%;
    position: center;
    repeat: no-repeat;
  };
}

.qr {
  position: relative;
  width: 7.5rem;
  height: 6.76rem;
  background: {
    image: url("~@/assets/images/ban_qr.png");
    size: 100%;
    position: center;
    repeat: no-repeat;
  };

  .qr-img {
    position: absolute;
    width: 2.7rem;
    height: 2.7rem;
    left: 2.5rem;
    top: 1.56rem;
    border-radius: .2rem;
    border: solid 0.02rem rgb(21, 40, 25);
    overflow: hidden;
    background: {
      color: #fff;
    };
  }

  .qr-img-pic {
    display: block;
    width: 100%;
    height: 100%;
  }

  .qr-link {
    position: absolute;
    left: 2.12rem;
    top: 4.87rem;
    display: block;
    width: 1.6rem;
    height: 0.5rem;
  }
}

</style>
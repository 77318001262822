<template>
  <div class="header">
    <div class="banner">
      <img class="banner-bg" src="~@/assets/images/banner.png" alt="">
    </div>

    <div class="desc-wrapper">
      <!-- 装饰 -->
      <img src="~@/assets/images/banner_desc_zs.png" class="desc-header"/>

      <div class="desc-out">
        <div class="desc-inner">
          <p class="desc-text">
            平安大学千人千面首页即将引入喜马拉雅精品资源，您期待看到什么呢?请在下面人气大咖秀、热门课程辑、超赞有声书选出您期待的内容，热门票选内容会在12月10日正式上线,来助ta出道吧!</p>
          <div class="desc-time">投票时间：11月30日-12月7日</div>
        </div>
      </div>

      <img src="~@/assets/images/banner_desc_zs2.png" class="desc-zs"/>

    </div>

    <div class="share" @click="clickShare">
      <img src="~@/assets/images/share_icon.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  methods: {
    clickShare: function () {
      // 随机三个文案
      let shareText = [
        {
          title: "我正在参加投你“喜”好活动，一起来投票吧！",
          content: "快来pick你期待的内容吧！",
        },
        {
          title: "奔走相告！平安大学千人千面即将上线喜马拉雅精品内容…",
          content: " 大咖讲书、精品课…精彩你说了算！",
        },
        {
          title: "喜马拉雅即将来袭！快来投你“喜”好！",
          content: "诚邀你参加平安大学投票活动！",
        }
      ];

      let random = parseInt(Math.floor(Math.random() * 3));

      zn.biz.util.znShare({
        type: 0,
        url: "https://pazn.leadmea.com/",
        title: shareText[random].title,
        content: shareText[random].content,
        image: "https://pazn.leadmea.com/html/img/ws_share_img.png"
      });

      MtaH5.clickStat("sharetop");
    },
  }
}
</script>

<style scoped lang="scss">
.header {
  padding-bottom: .45rem;
}

.banner {
  height: 6.95rem;
  margin-bottom: .55rem;

  .banner-bg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.desc-wrapper {
  position: relative;

  .desc-header {
    position: absolute;
    display: block;
    width: 2.41rem;
    height: .64rem;
    left: 50%;
    margin-left: -1.2rem;
    top: -0.4rem;
    pointer-events: none;
  }

  .desc-zs {
    position: absolute;
    display: block;
    width: .73rem;
    height: .51rem;
    top: -0.36rem;
    left: .18rem;
    pointer-events: none;
  }

  .desc-out {
    position: relative;
    width: 6.90rem;
    margin: 0 auto;
    border-width: 0.02rem;
    border-color: rgb(42, 28, 19);
    border-style: solid;
    border-radius: 0.2rem;
    background-color: rgb(246, 206, 73);
    padding: .1rem;
  }

  .desc-inner {
    margin: 0 auto;
    border-width: 0.02rem;
    border-color: rgb(42, 28, 19);
    border-style: solid;
    border-radius: 0.15rem;
    background-color: rgb(255, 245, 208);
    padding: 0.14rem 0.16rem 0.18rem 0.16rem;
    color: #222;
    font-size: .24rem;
    line-height: .38rem;
    text-align: center;
  }

  .desc-text {
    margin-bottom: .18rem;
  }

  .desc-time {
    display: inline-block;
    height: .38rem;
    line-height: .38rem;
    background-color: rgb(255, 230, 176);
    border-radius: .2rem;
    font-size: .24rem;
    color: #222;
    font-weight: bold;
    padding: 0 .26rem;
  }

}

.share {
  position: absolute;
  width: 1.18rem;
  height: .5rem;
  right: 0;
  top: .23rem;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>